<template>
  <div>
    <div class="row" style="padding: 22px; padding-top: 0px">
      <div class="col-md-12 escola-painel">
        <h4>
          <b>{{ escola.nome }}</b>
        </h4>
        <h6>
          <va-badge v-if="escola.codigo_INEP"
            :text="'INEP: ' + escola.codigo_INEP"
            color="warning"
            class="mr-2"
          />
          <va-badge v-if="escola.cnpj"
            :text="'CNPJ: ' + escola.cnpj"
            color="#154ec1"
            class="mr-4"
          />
        </h6>
        <p>
          <b>Endereço</b>
        </p>
        <p v-if="escola.distrito">Distrito: {{ escola.distrito }}</p>
        <p v-if="escola.numero">
          {{ escola.logradouro }} - {{ escola.numero }} -
          {{ escola.bairro }} - {{ escola.cidade }} -
          {{ escola.estado }}
        </p>
        <p v-else>
          {{ escola.logradouro }} - Sem Número - {{ escola.bairro }} -
          {{ escola.cidade }} - {{ escola.estado }}
        </p>
        <p><b>CEP: </b>{{ escola.cep }}</p>
        <hr />
        <p><b>Email: </b>{{ escola.email }}</p>
        <p><b>Telefone: </b>{{ escola.telefone }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "escolaVisualizar",
  props: {
    escola: [],
  },
  data() {
    return {};
  },
  methods: {

  },
};
</script>

<style>
.escola-painel{
  min-height: 200px;
  padding: 20px;
  background-color: #fbfbfb;
  border-radius: 5px;
}
</style>
